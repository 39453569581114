<template>
  <div class="page">
    <van-nav-bar title="反馈记录" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <div class="content">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          class="list-box"
        >
          <FeedItem
            v-for="(item, index) in list"
            :item="item"
            :key="index"
            class="item"
          ></FeedItem>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import Api from "@/api/caseHandle";
import { Toast } from "vant";

export default {
  components: {
    FeedItem: () => import("./components/FeedItem"),
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  methods: {
    async onLoad() {
      try {
        this.finished = false;
        this.loading = true;
        var res = await Api.findFeedbackList({
          // ...this.$route.query,
          applyBizNo: this.$route.query.projectCode,
        });
        const { data } = res;
        this.list = data;
        this.finished = true;
      } catch (error) {
        Toast.fail(error);
        this.list = [];
        this.finished = true;
      } finally {
        this.loading = false;
        this.refreshing = false;
      }
    },
    onRefresh() {
      this.onLoad();
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
@import "../../assets/css/variables.less";
.page {
  height: 100%;
  background: @pageBg;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    overflow-y: auto;
  }
}
</style>